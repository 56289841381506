import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#224D8F',
    },
    secondary: {
      main: '#b1b039',
    },
    background: {
      default: '#ffffff',
    },
  },
  props: {
    MuiTextField: {
      margin: 'normal',
    },
    MuiFormControl: {
      margin: 'normal',
    },
  },
});

export default responsiveFontSizes(theme);
