import { Box } from '@material-ui/core';
import logo from 'assets/images/logo.png';
import React, { FunctionComponent } from 'react';
import { useStyles } from './styles';

const CustomerInvoiceHeader: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Box className={classes.brandContainer}>
      <Box display="flex" alignItems="center" justifyContent="center">
        <img src={logo} alt="Logo" style={{ maxHeight: 75 }} />
      </Box>
    </Box>
  );
};

export default CustomerInvoiceHeader;
