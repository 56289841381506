import { Chip, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#e3e8ee',
    borderRadius: '4px',
    color: '#4f566b',
    fontWeight: 500,
  },
}));

type Props = {
  text: string;
  renderNullIfEmpty?: boolean;
};
const NeutralChip: FunctionComponent<Props> = ({
  text,
  renderNullIfEmpty = true,
}) => {
  const classes = useStyles();
  return !text && renderNullIfEmpty ? null : (
    <Chip size="small" label={text} className={classes.status} />
  );
};

export default NeutralChip;
