import { DateTime } from 'luxon';

export const formatDate = (datetime: string | null | undefined) =>
  datetime ? DateTime.fromISO(datetime).toLocaleString(DateTime.DATE_MED) : '';

export const formatDateTime = (datetime: string | null | undefined) =>
  datetime
    ? DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED)
    : '';

export const getPickupTime = (
  start: string | null | undefined,
  end: string | null | undefined,
  timeZone: string | null | undefined,
) => {
  if (!start || !end || !timeZone) {
    return null;
  }

  const startObj = DateTime.fromISO(start).setZone(timeZone);
  const endObj = DateTime.fromISO(end).setZone(timeZone);

  return {
    startDate: startObj.toLocaleString(DateTime.DATE_SHORT),
    endDate: endObj.toLocaleString(DateTime.DATE_SHORT),
    pickupTime: `${startObj.toLocaleString(
      DateTime.TIME_SIMPLE,
    )} - ${endObj.toLocaleString(DateTime.TIME_SIMPLE)}`,
  };
};
