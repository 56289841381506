import React from 'react';
import { Box, Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  listLabel: {
    color: '#757575',
    minWidth: 120,
    flexWrap: 'wrap',
  },
  listItem: {
    color: '#212121',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  listDescription: { maxWidth: 120 },
  listValue: { flexGrow: 1 },
}));

const LineItemsCondensed = ({
  description,
  value,
}: {
  description: string;
  value: any;
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" className={classes.listContainer}>
      <Typography
        variant="body2"
        className={classes.listDescription}
        component="div"
      >
        <Box className={classes.listLabel}>{description}</Box>
      </Typography>
      <Typography variant="body2" className={classes.listValue} component="div">
        <Box className={classes.listItem}>{value}</Box>
      </Typography>
    </Box>
  );
};

export default LineItemsCondensed;
