import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { publicRoutes } from './routes';

export default () => (
  <Switch>
    {publicRoutes.map(({ path, component: Component }) => (
      <Route key={path} exact path={path}>
        <Component />
      </Route>
    ))}
    {/* Catch all or 404 */}
    <Route path="*">
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    </Route>
  </Switch>
);
