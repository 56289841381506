import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { FunctionComponent } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  url?: string | null;
};
const CustomerInvoiceSignature: FunctionComponent<Props> = ({ url }) => {
  if (!url) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <LazyLoadImage
        alt="customer signature"
        src={url}
        visibleByDefault
        placeholder={<Skeleton animation={false} width={250} height={250} />}
        style={{
          borderRadius: '4px',
          maxHeight: 250,
          maxWidth: 250,
        }}
      />
    </Grid>
  );
};

export default CustomerInvoiceSignature;
