import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UndoIcon from '@material-ui/icons/Undo';
import NeutralChip from 'components/Chips/NeutralChip';
import ContentHeader from 'components/ContentHeader';
import { GetCustomerInvoiceByIdAndTokenQuery } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { formatItemNote, openInNewTab } from 'utils/general';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';
import { STOW_SCHEDULE_LINK, BROOKPARK_SCHEDULE_LINK } from 'utils/env';
import { useStyles } from './styles';

const Item = ({ first, second }: { first: string; second: any }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.item}>
      <Grid item xs>
        {first}
      </Grid>
      <Grid item xs>
        <Typography variant="body2" className={classes.bidAmount}>
          {second}
        </Typography>
      </Grid>
    </Grid>
  );
};

type Props = {
  data: GetCustomerInvoiceByIdAndTokenQuery | undefined;
};
const CustomerInvoiceItems: FunctionComponent<Props> = ({ data }) => {
  const classes = useStyles();
  const customerInvoice = data?.getCustomerInvoice;
  const invoice = customerInvoice?.invoice;
  const items = customerInvoice?.items ?? [];
  const adjustments = customerInvoice?.adjustments ?? [];
  const total = invoice?.total;
  const amountDue = invoice?.amountDue;
  const appliedCredit = invoice?.appliedCredit;
  const appliedPayment = invoice?.appliedPayment;
  const schedulePickupLink = useMemo(() => {
    const auction = customerInvoice?.auction?.name;
    if (auction) {
      if (/stow/gi.test(auction)) {
        return STOW_SCHEDULE_LINK;
      }
      if (/brookpark/gi.test(auction)) {
        return BROOKPARK_SCHEDULE_LINK;
      }
    }
    return undefined;
  }, [customerInvoice]);

  const onSchedule = useCallback(() => {
    if (schedulePickupLink) {
      openInNewTab(schedulePickupLink);
    }
  }, [schedulePickupLink]);

  return (
    <Box>
      <Accordion
        defaultExpanded={false}
        classes={{
          root: classes.accordionRoot,
          expanded: classes.accordionExpanded,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2" className={classes.heading}>
            {`Items (${items.length})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid className={classes.itemContainer}>
            {items.map((item, i) => (
              <Grid key={String(i)} className={classes.item} container>
                <Grid item xs={10}>
                  <Grid container direction="column">
                    <Grid item zeroMinWidth xs>
                      <Typography
                        variant="body2"
                        className={classes.description}
                      >
                        {`#${item?.number}: ${
                          item?.description
                        } ${formatItemNote(
                          item?.retailPrice,
                          item?.condition,
                        )}`}
                      </Typography>
                    </Grid>
                    {item?.returns?.map((itemReturn) => (
                      <Grid
                        key={String(itemReturn?.id)}
                        container
                        xs
                        style={{ paddingLeft: 10, paddingTop: 10 }}
                        direction="row"
                      >
                        <Grid
                          item
                          xs
                          style={{
                            flexGrow: 0,
                            paddingRight: 20,
                            alignSelf: 'center',
                          }}
                        >
                          <UndoIcon />
                        </Grid>
                        <Grid item xs>
                          <Grid item xs>
                            <Typography variant="body2">
                              {itemReturn?.reason}
                            </Typography>
                          </Grid>
                          <Box
                            display="flex"
                            style={{ paddingTop: 5, paddingBottom: 5 }}
                          >
                            {itemReturn?.creditAmount ? (
                              <Box paddingRight={1}>
                                <NeutralChip
                                  text={`Credit ${formatCentsToDollars(
                                    itemReturn?.creditAmount,
                                  )}`}
                                />
                              </Box>
                            ) : null}
                            {itemReturn?.paymentAmount ? (
                              <Box paddingRight={1}>
                                <NeutralChip
                                  text={`Refund ${formatCentsToDollars(
                                    itemReturn?.paymentAmount,
                                  )}`}
                                />
                              </Box>
                            ) : null}
                            {itemReturn?.alternatePaymentAmount ? (
                              <Box paddingRight={1}>
                                <NeutralChip
                                  text={`Alternate ${formatCentsToDollars(
                                    itemReturn?.alternatePaymentAmount,
                                  )}`}
                                />
                              </Box>
                            ) : null}
                          </Box>
                          <Grid item xs>
                            <Typography variant="caption">
                              {formatDateTime(itemReturn?.createdAt)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.bidAmount}>
                    {formatCentsToDollars(item?.bidAmount)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {adjustments?.length > 0 && (
              <Grid className={classes.adjustmentContainer}>
                <ContentHeader title="Fees" />
                {adjustments.map((adjustment, i) => (
                  <Grid
                    key={String(adjustment?.id ?? i)}
                    className={classes.item}
                    container
                  >
                    <Grid item xs={10}>
                      <Grid container direction="column">
                        <Grid item zeroMinWidth xs>
                          <Typography
                            variant="body2"
                            className={classes.description}
                          >
                            {adjustment?.type}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body2" className={classes.bidAmount}>
                        {formatCentsToDollars(adjustment?.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {!!schedulePickupLink && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '1rem 0',
          }}
        >
          <Box style={{ display: 'flex', flexGrow: 1, marginRight: '0.5rem' }}>
            <Typography variant="body2">
              Have 5 items or more to pick up?
            </Typography>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Button
              disableElevation
              variant="contained"
              startIcon={<EventIcon />}
              style={{ textTransform: 'none', whiteSpace: 'nowrap' }}
              onClick={onSchedule}
            >
              Schedule pickup
            </Button>
          </Box>
        </Box>
      )}
      <Box className={classes.summaryContainer}>
        <Grid>
          <ContentHeader title="Summary" />
          <Item
            first="Item subtotal"
            second={formatCentsToDollars(invoice?.itemBidTotal)}
          />
          <Item
            first="Premium"
            second={formatCentsToDollars(invoice?.itemPremiumTotal)}
          />
          <Item
            first="Tax"
            second={formatCentsToDollars(invoice?.itemTaxTotal)}
          />
          <Item first="Total" second={formatCentsToDollars(total)} />
          {appliedCredit !== null &&
          appliedCredit !== undefined &&
          Number(appliedCredit) !== 0 ? (
            <Item
              first="Applied credit"
              second={formatCentsToDollars(appliedCredit)}
            />
          ) : null}
          {appliedPayment !== null &&
          appliedPayment !== undefined &&
          Number(appliedPayment) !== 0 ? (
            <Item
              first="Applied payment"
              second={formatCentsToDollars(appliedPayment)}
            />
          ) : null}
          <Item first="Amount due" second={formatCentsToDollars(amountDue)} />
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerInvoiceItems;
