import { GRAPHQL_SERVER_URL, IS_PRODUCTION } from 'utils/env';

const commonConfig = {
  graphqlServerUrl: GRAPHQL_SERVER_URL,
};

const development = {};

const production = {};

let config; // eslint-disable-line

if (IS_PRODUCTION) {
  config = production;
} else {
  config = development;
}

export default {
  ...commonConfig,
  ...config,
} as any;
