import React from 'react';
import { toast } from 'react-toastify';
import LoadingSnackbar from './LoadingSnackbar';

export const generalNotification = (message: string) =>
  toast(<LoadingSnackbar message={message} loading={false} />, {
    style: { backgroundColor: '#e3e3e3' },
  });

export const startSnackbar = (message: string) =>
  toast(<LoadingSnackbar message={message} loading />, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
  });

export const stopSnackbar = (message: string, snackId: string | number) =>
  toast.update(snackId, {
    render: <LoadingSnackbar message={message} loading={false} />,
    autoClose: 5000,
    closeButton: false,
    closeOnClick: true,
    draggable: false,
  });
