const {
  NODE_ENV,
  REACT_APP_ENV: ENV,
  REACT_APP_GRAPHQL_SERVER_URL,
  REACT_APP_AUTHORIZE_LOGIN_KEY,
  REACT_APP_AUTHORIZE_CLIENT_KEY,
  REACT_APP_AUTHORIZE_ENV,
} = process.env;

if (!REACT_APP_GRAPHQL_SERVER_URL) {
  throw new Error('REACT_APP_GRAPHQL_SERVER_URL missing');
}

if (!REACT_APP_AUTHORIZE_LOGIN_KEY) {
  throw new Error('REACT_APP_AUTHORIZE_LOGIN_KEY missing');
}

if (!REACT_APP_AUTHORIZE_CLIENT_KEY) {
  throw new Error('REACT_APP_AUTHORIZE_CLIENT_KEY missing');
}

if (!REACT_APP_AUTHORIZE_ENV) {
  throw new Error('REACT_APP_AUTHORIZE_ENV missing');
}

if (
  REACT_APP_AUTHORIZE_ENV !== 'sandbox' &&
  REACT_APP_AUTHORIZE_ENV !== 'production'
) {
  throw new Error(
    'REACT_APP_AUTHORIZE_ENV needs to be "sandbox" or "production',
  );
}

const IS_NODE_PRODUCTION = NODE_ENV === 'production';

export const IS_PRODUCTION = IS_NODE_PRODUCTION && ENV === 'production';
export const IS_STAGING = IS_NODE_PRODUCTION && ENV === 'staging';
export const IS_TESTING = IS_NODE_PRODUCTION && ENV === 'testing';
export const IS_LOCAL = ENV === 'local';

export const GRAPHQL_SERVER_URL = REACT_APP_GRAPHQL_SERVER_URL;

export const AUTHORIZE_LOGIN_KEY = REACT_APP_AUTHORIZE_LOGIN_KEY;
export const AUTHORIZE_CLIENT_KEY = REACT_APP_AUTHORIZE_CLIENT_KEY;
export const AUTHORIZE_ENV = <'production' | 'sandbox'>REACT_APP_AUTHORIZE_ENV;

export const STOW_SCHEDULE_LINK =
  'https://app.squarespacescheduling.com/schedule.php?owner=26079284&location=Stow%2C%20OH';
export const BROOKPARK_SCHEDULE_LINK =
  'https://app.squarespacescheduling.com/schedule.php?owner=26079284&calendarID=6779631';
