import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ApolloClient from 'config/ApolloClient';
import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootRouter from 'routes/RootRouter';
import styled from 'styled-components';
import theme from 'theme';

const StyledToastContainer = styled(ToastContainer).attrs({
  toastClassName: 'toast',
})`
  .toast {
    background-color: #224d8f;
    border-radius: 4px;
    box-shadow: 0px 2px 10px 2px rgba(4, 18, 88, 0.3);
    color: #ffffff;
    min-height: auto;
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

const App: FunctionComponent = () => (
  <Router>
    <ApolloProvider client={ApolloClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RootRouter />
        <StyledToastContainer
          position="bottom-center"
          hideProgressBar
          transition={Slide}
          autoClose={false}
          closeOnClick={false}
          toastClassName="toast"
        />
      </ThemeProvider>
    </ApolloProvider>
  </Router>
);

export default App;
