import { ApolloClient, InMemoryCache } from '@apollo/client';
import config from 'config/config';

export const RootCache = new InMemoryCache();

const client = new ApolloClient({
  cache: RootCache,
  uri: config.graphqlServerUrl,
});

export default client;
