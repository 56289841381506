import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { startSnackbar, stopSnackbar } from 'components/SnackBar/index';
import { usePayCustomerInvoiceMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormComponent, FormContainer } from 'react-authorize-net';
import {
  AUTHORIZE_CLIENT_KEY,
  AUTHORIZE_ENV,
  AUTHORIZE_LOGIN_KEY,
} from 'utils/env';
import { logError } from 'utils/logging';
import { useStyles } from './styles';

type Props = {
  amountDue: number;
  invoiceId: string;
  onSuccess: () => void;
};
const CustomerInvoicePayment: FunctionComponent<Props> = ({
  amountDue,
  invoiceId,
  onSuccess,
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState([]);
  const [saveForFuture, setSaveForFuture] = useState(true);
  const [payCustomerInvoice] = usePayCustomerInvoiceMutation();

  const onErrorCallback = (response: any) => {
    setErrors(response?.messages?.message?.map((err: any) => err?.text));
  };

  const onSuccessCallback = useCallback(
    async (response: any) => {
      const toastId = startSnackbar('Starting payment');
      const dataDescriptor = response?.opaqueData?.dataDescriptor;
      const dataValue = response?.opaqueData?.dataValue;
      if (invoiceId && dataDescriptor && dataValue) {
        try {
          await payCustomerInvoice({
            variables: {
              invoiceId,
              dataDescriptor,
              dataValue,
              saveForFutureUse: saveForFuture,
            },
          });
          stopSnackbar('Thank you for your payment', toastId);
          onSuccess();
        } catch (e) {
          stopSnackbar(e.message, toastId);
          logError(e);
        }
      }
    },
    [invoiceId, saveForFuture],
  );

  if (amountDue <= 0) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Paper variant="outlined">
        <Box>
          <FormContainer
            environment={AUTHORIZE_ENV}
            onError={onErrorCallback}
            onSuccess={onSuccessCallback}
            amount={amountDue / 100}
            component={(props) => {
              const { validationErrors } = props;
              const isValid =
                validationErrors.cardCode &&
                validationErrors.cardNumber &&
                validationErrors.expDate;
              return (
                <FormComponent
                  {...props}
                  style={{
                    form: {
                      backgroundColor: '#ffffff',
                      marginBottom: '0px',
                      padding: '16px',
                    },
                    input: {
                      backgroundColor: 'white',
                      color: 'black',
                      border: '1px solid #e0e0e0',
                      height: '4.5rem',
                      marginBottom: '1rem',
                      padding: '1rem',
                    },
                    button: {
                      backgroundColor: isValid ? '#224D8F' : '#e0e0e0',
                      border: '1px solid #e0e0e0',
                      boxShadow: 'none',
                      color: isValid ? '#ffffff' : '#000000',
                    },
                  }}
                />
              );
            }}
            clientKey={AUTHORIZE_CLIENT_KEY}
            apiLoginId={AUTHORIZE_LOGIN_KEY}
          />
          <Box className={classes.saveForFutureContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={saveForFuture}
                  onChange={(e) => setSaveForFuture(e.target.checked)}
                  name="saveForFuture"
                  color="primary"
                />
              }
              label="Save payment for future use"
            />
          </Box>
        </Box>
        <Grid container className={classes.errorsContainer}>
          {errors.map((error, i) => (
            <Grid key={String(i)} item xs={12}>
              <ErrorIcon fontSize="small" color="error" />
              <Typography display="inline">{error}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CustomerInvoicePayment;
