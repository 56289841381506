import { Chip, makeStyles } from '@material-ui/core';
import Icon from '@material-ui/icons/Done';
import React from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: '#cbf4c9',
    borderRadius: '4px',
    color: '#0e6245',
    fontWeight: 500,
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
};
export default ({ label = 'successful', icon = true }: Props) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      icon={icon ? <Icon /> : undefined}
    />
  );
};
