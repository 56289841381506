import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {},
  container: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '600px',
  },
  brandContainer: { marginBottom: theme.spacing(1) },
  brandTitle: {
    color: theme.palette.grey[700],
    fontWeight: 500,
  },
  header: {
    backgroundColor: '#224D8F',
    boxShadow:
      '1px 0px #224D8F, 0px 1px #224D8F, -1px 0px #224D8F, 0px -1px #224D8F',
    borderRadius: '8px',
    border: '0px solid #ffffff',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0),
    padding: theme.spacing(3, 0, 0, 0),
  },
  headerMain: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2, 2, 2),
  },
  headerOverline: { color: theme.palette.grey[50], fontWeight: 600 },
  headerPrimary: { marginBottom: theme.spacing(2) },
  headerPrimaryText: { color: theme.palette.grey[50] },
  headerSecondary: { marginBottom: theme.spacing(0) },
  heading: {
    color: theme.palette.grey[800],
    fontSize: theme.typography.pxToRem(15),
  },
  valueFloatRight: { float: 'right' },
  lineDashed: {
    borderBottom: '1px dashed #e1e1e1',
    marginBottom: theme.spacing(1),
  },
  chipStatus: {
    backgroundColor: '#cbf4c9',
    borderRadius: '4px',
    color: '#0e6245',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  accordionRoot: {
    backgroundColor: '#ffffff',
    boxShadow:
      '1px 0px #e0e0e0, 0px 1px #e0e0e0, -1px 0px #e0e0e0, 0px -1px #e0e0e0',
  },
  accordionExpanded: { margin: '0!important' },
  listHeader: { marginBottom: theme.spacing(1) },
  cardHeader: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  productCard: { marginBottom: theme.spacing(2) },
  cardHeaderLot: { marginBottom: theme.spacing(1.2) },
  cardHeaderDescription: { marginBottom: theme.spacing(2.5) },
  cardHelp: { padding: theme.spacing(2) },
  listContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  listLabel: {
    color: '#ffffff',
    minWidth: 180,
    flexWrap: 'wrap',
  },
  listItem: {
    color: '#ffffff',
    flexWrap: 'wrap',
  },
  listDescription: { maxWidth: 120 },
  listValue: { maxWidth: 340 },
  item: {
    paddingTop: theme.spacing(2),
  },
  chip: {
    paddingLeft: '5px',
  },
  description: {
    fontWeight: 400,
  },
  number: {
    fontWeight: 300,
    paddingRight: theme.spacing(1),
  },
  bidAmount: {
    fontWeight: 500,
    textAlign: 'right',
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  itemContainer: {
    paddingBottom: theme.spacing(4),
    flexGrow: 1,
  },
  adjustmentContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  summaryContainer: {
    paddingTop: theme.spacing(4),
  },
  errorsContainer: {
    padding: theme.spacing(2),
  },
  qrWrap: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(1.5),
  },
  saveForFutureContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default useStyles;
