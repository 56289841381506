import { formatCentsToDollars } from './money';

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const formatItemNote = (
  retailPrice: number | undefined | null,
  condition: string | undefined | null,
) =>
  `Retail Price: ${
    retailPrice ? formatCentsToDollars(retailPrice) : 'unknown'
  }. Condition: ${condition ?? 'unknown'}`;
