import NotFoundScreen from 'screens/NotFoundScreen';
import CustomerInvoiceViewScreen from 'screens/CustomerInvoice/CustomerInvoiceViewScreen';

export const publicRoutes = [
  { path: '/', component: NotFoundScreen },
  {
    path: '/customers/invoice/:id/:token',
    component: CustomerInvoiceViewScreen,
  },
];

export const privateRoutes = [];
