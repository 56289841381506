import { Box, Container, Grid, Typography } from '@material-ui/core';
import Loading from 'components/CenterLoading';
import CustomerInvoiceBody from 'components/CustomerInvoice/CustomerInvoiceBody';
import CustomerInvoiceHeader from 'components/CustomerInvoice/CustomerInvoiceHeader';
import CustomerInvoiceItems from 'components/CustomerInvoice/CustomerInvoiceItems';
import CustomerInvoicePayment from 'components/CustomerInvoice/CustomerInvoicePayment';
import CustomerInvoiceSignature from 'components/CustomerInvoice/CustomerInvoiceSignature';
import { useStyles } from 'components/CustomerInvoice/styles';
import { useGetCustomerInvoiceByIdAndTokenLazyQuery } from 'graphql/graphql-types';
import { useQuery } from 'hooks/router';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const CustomerInvoiceViewScreen: FunctionComponent = () => {
  const classes = useStyles();
  const { id, token } = useParams<{ id: string; token: string }>();
  const urlQueryParams = useQuery();
  const [runQuery, { data, loading, error, refetch }] =
    useGetCustomerInvoiceByIdAndTokenLazyQuery();

  const t = urlQueryParams.get('t');

  useEffect(() => {
    if (id && token) {
      runQuery({
        variables: {
          id: String(id),
          token: String(token),
          elevatedToken: t,
        },
      });
    }
  }, [id, token, t]);

  const invoice = data?.getCustomerInvoice?.invoice;

  return (
    <Container className={classes.wrapper}>
      <Box className={classes.container}>
        {loading && <Loading />}
        {!loading && error && <Typography>Invoice not found.</Typography>}
        {!loading && !error && data && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CustomerInvoiceHeader />
            </Grid>
            <Grid item xs={12}>
              <CustomerInvoiceBody
                data={data}
                invoiceUid={id}
                invoiceToken={token}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomerInvoiceItems data={data} />
            </Grid>
            <CustomerInvoiceSignature
              url={data?.getCustomerInvoice?.signatureUrl}
            />
            <CustomerInvoicePayment
              onSuccess={() => refetch?.()}
              amountDue={Number(invoice?.amountDue)}
              invoiceId={String(invoice?.id)}
            />
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default CustomerInvoiceViewScreen;
