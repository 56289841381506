import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LineItemsCondensed from 'components/LineItemsCondensed';
import PaymentStatusChip from 'components/PaymentStatusChip';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  GetCustomerInvoiceByIdAndTokenQuery,
  useDownloadCustomerInvoiceMutation,
} from 'graphql/graphql-types';
import QRCode from 'qrcode.react';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { openInNewTab } from 'utils/general';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import { formatDate, getPickupTime } from 'utils/time';
import { useStyles } from './styles';

type Props = {
  data: GetCustomerInvoiceByIdAndTokenQuery | undefined;
  invoiceUid: string;
  invoiceToken: string;
};
const CustomerInvoiceBody: FunctionComponent<Props> = ({
  data,
  invoiceUid,
  invoiceToken,
}) => {
  const classes = useStyles();
  const [downloading, setDownloading] = useState(false);
  const [download] = useDownloadCustomerInvoiceMutation();

  const onDownload = useCallback(() => {
    const handleDownload = async () => {
      const snackId = startSnackbar('Preparing invoice for download');
      setDownloading(true);
      try {
        const result = await download({
          variables: {
            input: {
              invoiceUid,
              publicToken: invoiceToken,
            },
          },
        });
        const downloadUrl = result?.data?.downloadCustomerInvoice?.downloadUrl;
        if (downloadUrl) {
          openInNewTab(downloadUrl);
        }
        stopSnackbar('Downloaded invoice', snackId);
      } catch (e) {
        logError(e);
        stopSnackbar('Failed to download invoice', snackId);
      } finally {
        setDownloading(false);
      }
    };
    if (invoiceUid && invoiceToken) {
      handleDownload();
    }
  }, [invoiceUid, invoiceToken]);

  const customerInvoice = data?.getCustomerInvoice;
  const auction = customerInvoice?.auction;
  const customer = customerInvoice?.customer;
  const invoice = customerInvoice?.invoice;
  const payment = customerInvoice?.payment;

  const paymentExists = !!(payment?.cardType && payment?.cardNumber);

  const pickupTime = getPickupTime(
    auction?.pickupStartAt,
    auction?.pickupEndAt,
    auction?.timezone,
  );

  return (
    <>
      <Paper variant="outlined" className={classes.header}>
        <Box className={classes.headerMain}>
          <Grid container justify="space-between">
            <Grid item>
              <Box>
                <Typography variant="overline" component="div">
                  <Box className={classes.headerOverline}>{auction?.name}</Box>
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className={classes.headerPrimary}
              >
                <Typography variant="h4" component="div">
                  <Box className={classes.headerPrimaryText}>
                    {formatCentsToDollars(invoice?.total)}
                  </Box>
                </Typography>
                <Box className={classes.chip}>
                  <PaymentStatusChip
                    type={Number(invoice?.amountDue) > 0 ? 'unpaid' : 'paid'}
                  />
                </Box>
              </Box>
              <Box className={classes.headerSecondary}>
                <Box display="flex" className={classes.listContainer}>
                  <Typography
                    variant="body2"
                    className={classes.listDescription}
                    component="div"
                  >
                    <Box className={classes.listLabel}>Invoice #</Box>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.listValue}
                    component="div"
                  >
                    <Box className={classes.listItem}>{invoice?.uid}</Box>
                  </Typography>
                </Box>
                <Box display="flex" className={classes.listContainer}>
                  <Typography
                    variant="body2"
                    className={classes.listDescription}
                    component="div"
                  >
                    <Box className={classes.listLabel}>Issued Date</Box>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.listValue}
                    component="div"
                  >
                    <Box className={classes.listItem}>
                      {formatDate(auction?.endAt)}
                    </Box>
                  </Typography>
                </Box>
                {paymentExists ? (
                  <Box display="flex" className={classes.listContainer}>
                    <Typography
                      variant="body2"
                      className={classes.listDescription}
                      component="div"
                    >
                      <Box className={classes.listLabel}>Payment method</Box>
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.listValue}
                      component="div"
                    >
                      <Box className={classes.listItem}>
                        {`${payment?.cardType} ${payment?.cardNumber}`}
                      </Box>
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.qrWrap}>
                <QRCode
                  className="code"
                  size={100}
                  value={JSON.stringify({
                    type: 'invoice',
                    invoiceId: invoice?.id,
                  })}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Accordion
          defaultExpanded={false}
          classes={{
            root: classes.accordionRoot,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" className={classes.heading}>
              Customer
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <LineItemsCondensed
                description="Name"
                value={`${customer?.firstName} ${customer?.lastName}`}
              />
              <LineItemsCondensed description="Email" value={customer?.email} />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded={false}
          classes={{
            root: classes.accordionRoot,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" className={classes.heading}>
              Auction
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <LineItemsCondensed description="Name" value={auction?.name} />
              <LineItemsCondensed
                description="Date"
                value={formatDate(auction?.endAt)}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded={false}
          classes={{
            root: classes.accordionRoot,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle2" className={classes.heading}>
              Pickup Instructions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <LineItemsCondensed
                description="Date"
                value={`${pickupTime?.endDate} between ${pickupTime?.pickupTime}`}
              />
              <LineItemsCondensed
                description="Location"
                value={`${auction?.pickupAddress}, ${auction?.pickupCity}, ${auction?.pickupState} ${auction?.pickupZipcode}`}
              />
              <LineItemsCondensed
                description="Instructions"
                value="Please bring a valid drivers license along with a printed or electronic copy of this invoice."
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Button
          disableElevation
          variant="contained"
          startIcon={<ArrowDownwardIcon />}
          style={{ textTransform: 'none' }}
          onClick={onDownload}
          disabled={downloading || !invoiceToken || !invoiceUid}
        >
          Download Invoice
        </Button>
      </Box>
    </>
  );
};

export default CustomerInvoiceBody;
